import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Topbar from '../Topbar';
import './index.scss';
import { useAuth0 } from '@auth0/auth0-react';

const Layout = () => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const location = useLocation();

  // Define the routes that do not require authentication
  const unprotectedRoutes = ['/', '/test', '/testresult']; // Add more paths as needed

  useEffect(() => {
    const checkSession = async () => {
      try {
        // Attempt to get a token silently
        await getAccessTokenSilently();
      } catch (error) {
        if (error.error === 'login_required') {
          // If a login is required, redirect the user to the login page
          loginWithRedirect();
        } else {
          console.error('An error occurred during silent authentication:', error);
        }
      }
    };

    // Perform the check only if the current route is protected
    if (!unprotectedRoutes.includes(location.pathname) && !isAuthenticated) {
      checkSession();
    }
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect, location.pathname]);

  return (
    <div className='App'>
      <Topbar />
      <div className='page'>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
